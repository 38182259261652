import React from "react";
import { Container, Breadcrumb, Row, Col } from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Post } from "../../components/Core";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import DataTable from '../../components/data/banquePro/banquePro'
import DataTableMobile from '../../components/data/banquePro/banqueProMobile'
import Disclosure from '../../components/Disclosure'
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUser, faUsers, faCity} from '@fortawesome/free-solid-svg-icons'

function startabusiness() {
	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
				<title>Banque pour SARL - Meilleure banque pro pour votre SARL ou EURL</title>
                <meta name="description" content="
            Vous avez une société de type SARL ou EURL? Trouvez la meilleure banque pro avec bankbank." />

        </Helmet>
      	<Header />
   
        <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Banque Pro</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Banque Pro Pour SARL (EURL)</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}>Banque pro pour une SARL (EURL)</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banques pro pour SARL</span> pour déterminer vos priorités : employé.e.s, tarifs, services inclus.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque pro</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faUser} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Société avec 1 employé.e </Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faUsers} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 2 - 5 employé.e.s</Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 6 - 50 employé.e.s</Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faCity} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Plus de 50 employé.e.s</Tab>
    </TabList>

    <TabPanel>
      <DataTable type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='mobileTable'/>
    </TabPanel>
      <TabPanel>      
      <DataTable type='Société' row={[12,4,18,9,19,30,20,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[12,4,18,9,19,30,20,40]} id='mobileTable'/>
    </TabPanel>
      <TabPanel>    
      <DataTable type='Société' row={[9,21,4,22,23,31,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[9,21,4,22,23,31,40]} id='mobileTable'/>
    </TabPanel>
    <TabPanel>
      <DataTable type='Société' row={[9,21,5,22,23,24,25,26,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='mobileTable'/>
    </TabPanel>

  </Tabs>
            <Row className="justify-content-center">
              <Col>
    
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>


		  
			<Post>

			<h2>Comment choisir la meilleure banque pour une EURL ou une SARL ?</h2>
<p>La société de type SARL est le statut d’entreprise préféré des entreprises familiales. C’est une forme particulièrement intéressante pour le statut de conjoint collaborateur qui donne des avantages à la personne mariée ou pacsée du chef d’entreprise.
</p>
<StaticImage
		  	src='../../images/banque-pro-sarl-eurl.jpeg'
			width={1200}
			alt='Banque pro sarl eurl'
			placeholder='tracedSVG'
			id='pickky'
			/>
<p>Si la SAS connaît la plus forte croissance pour les nouvelles créations, la SARL représente près de 50% de toutes les sociétés crées en France.
</p><p>
Le quotidien d’une entreprise est déjà assez difficile. Trouver la meilleure banque pro pour votre activité vous permettra d’enlever le poid des tâches administratives non nécessaires.
</p><p>
Plusieures questions à vous poser pour mieux choisir votre banque :
</p>
<ul>
	<li>Acceptez-vous le paiement en liquide ?</li>
	<li>Avez-vous besoin d’encaisser de nombreux chèques ?</li>
	<li>Avez-vous besoin d’un terminal de paiement ?</li>
	<li>Les reçus et les notes de frais font-elle partie votre quotidien ?</li>
</ul>





				</Post>
		</Container>

		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
